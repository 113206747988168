import React from "react"
import './Event.scss'

const Event = (props) => (
  <div className="timeline-container">
    <div className="timeline__item">
      <div className="left">
        <span className="date">{props.event.node.frontmatter.date_end}</span>
        <span className={"status-label "+props.event.node.frontmatter.status.toLowerCase()}>{props.event.node.frontmatter.status}</span>
      </div>
      <div className="right">
        <div className="timeline__item-header">
          <h2>{props.event.node.frontmatter.title} | <span>{props.event.node.frontmatter.topic}</span></h2>
          <div dangerouslySetInnerHTML={{__html: props.event.node.html }} />
        </div>
      </div>
    </div>
  </div>
)

export default Event