import React from "react"
import { graphql } from "gatsby"
import SEO from '../components/seo'
import Layout from "../components/Layout"
import Contact from "../components/Contact"
import Timeline from "../components/Timeline"
import seoImage from "../images/socials.png"

export default ({data}) => (
  <Layout>
    <SEO title="Roadmap" image={seoImage} />
    <div className="jumbotron">
      <div className="jumbotron-inner">
        <div className="container">
          <div className="hero-wrapper">
            <div className="hero">
              <h1 dangerouslySetInnerHTML={{__html: data.dataYaml.title}}/>
              <p dangerouslySetInnerHTML={{__html: data.dataYaml.intro}}/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Timeline events={data.allMarkdownRemark.edges} />
    <Contact/>
  </Layout>
)
export const pageQuery = graphql`  
query MyQuery {
    dataYaml {
        title
        intro
    }
    allMarkdownRemark(sort: {fields: frontmatter___date_end, order: DESC}) {
        edges {
            node {
                html
                frontmatter {
                    date_end(locale: "en", formatString: "MMMM YYYY")
                    date_start(locale: "en", formatString: "MMMM YYYY")
                    status
                    title
                    topic
                }
            }
        }
    }
}
`