import React, { Component } from "react"
import "./Layout.scss"
import Navigation from "./Navigation"
import Footer from "./Footer"

class Layout extends Component {
  render() {
    return (
      <>
        <Navigation />
        <div className="page-container main-block">
          <div className="page">{this.props.children}</div>
          <Footer />
        </div>
      </>
    )
  }
}

export default Layout
