import React, { Component } from "react"
import PropTypes from "prop-types"
import Event from "./Event"
import "./Timeline.scss"

class Timeline extends Component {
  state = {
    events: this.props.events,
    searchTerm: "",
    checkboxes: {
      community: true,
      finance: true,
      website: true,
    },
  }

  handleChange = name => e => {
    const target = e.target
    const value = target.type === "checkbox" ? target.checked : target.value
    //const name = target.name
    let checkboxes = this.state.checkboxes
    checkboxes[target.name] = value
    //this.setState({checkboxes})
    this.setState({
      events: this.filteredEvents(this.props.events, checkboxes)
    })
  }

  filteredEvents = (events, conditions) => {
    let results = Object.keys(conditions).filter(function(x) {
      return conditions[x] !== false
    })

    return events.filter(function(event) {
      let topic = event.node.frontmatter.topic != undefined ? event.node.frontmatter.topic.toLowerCase() : undefined
      let valid = false
      if(results != null){
        results.forEach(result => {
          if(topic !== undefined && topic.includes(result)){
            valid = true
          }
        })
      }
      return valid
    })


  }

  render() {
    return (
      <div className="timeline">
        <div className="timeline-container">
          <div className="timeline__item">
            <div className="left no-border"></div>
            <div className="right no-border">
              <div className="menu-filter">
                <h2>Show me</h2>
                <div className="timeline-input-control-check">
                  <label htmlFor="community">
                    Community development
                    <input
                      id={"community"}
                      name={"community"}
                      type={"checkbox"}
                      defaultChecked={this.state.checkboxes.community}
                      onChange={this.handleChange("website")}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="timeline-input-control-check">
                  <label htmlFor="finance">
                    Finance and accounting
                    <input
                      id={"finance"}
                      name={"finance"}
                      type={"checkbox"}
                      value={"finance"}
                      defaultChecked={this.state.checkboxes.finance}
                      onChange={this.handleChange("finance")}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="timeline-input-control-check">
                  <label htmlFor="website">
                    Website
                    <input
                      id={"website"}
                      name={"website"}
                      type={"checkbox"}
                      value={"website"}
                      defaultChecked={this.state.checkboxes.website}
                      onChange={this.handleChange("website")}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="past">
          {this.state.events.map((event, index) => (
            <Event key={index} event={event} />
          ))}
        </div>
      </div>
    )
  }
}

Timeline.propTypes = {}

export default Timeline
