import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { faFacebookSquare, faLinkedinIn, faTwitterSquare, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Socials = (data, props) => (
  <StaticQuery
  query={graphql`
      query {
        site {
            siteMetadata {
                social{
                    name
                    url
                }
            }
        }
      }
    `}
  render={data => (
    <>
            {data.site.siteMetadata.social.map(social => {
                let faIcon
                switch (social.name) {
                  case 'facebook':
                    faIcon = faFacebookSquare
                    break;
                  case 'twitter':
                    faIcon = faTwitterSquare
                    break;
                  case 'linkedin':
                    faIcon = faLinkedinIn
                    break;
                  case 'youtube':
                    faIcon = faYoutube
                    break;
                  default:
                    faIcon = faFacebookSquare;
                }
                return(
                  <li key={social.name}>
                    <a href={social.url} title={social.name}>
                      <FontAwesomeIcon icon={faIcon} />
                    </a>
                  </li>
                )
              }
            )}
    </>
  )}
/>
)

export default Socials