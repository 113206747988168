import React from "react"
import { Link } from "gatsby"
import './Footer.scss'
import Socials from "./Socials"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-solid-svg-icons"

const Footer = (data, props) => (
  <div className="footer">
    <div className="footer-inner">
      <div className={'footer-top'}>
        <div className={'footer-block about-us'}>
          <h4>Where to find us</h4>
          <address>
            Swiss Esports Federation<br />
            Postgasse 15-21<br />
            3011 Berne<br />
            Switzerland<br />
          </address>
        </div>
        <div className={'footer-block footer-navigation'}>
          <ul className={'footer-navigation-list'}>
            <li className={'footer-navigation-item'}><a href="https://sesf.ch" title={'SeSF official website'}>SESF</a></li>
            <li className={'footer-navigation-item'}><Link to={'#contact'}>Contact Us</Link></li>
          </ul>
        </div>
        <div className="footer-block logo-socials">
          <div className={'follow-us'}>
            <h4>Follow us</h4>
            <ul>
              <Socials/>
            </ul>
          </div>

        </div>
      </div>
      <div className={'footer-bottom'}>
        <div className={'credits'}>
          <p>
            ©<strong>Swiss Esports Federation</strong> 2019. All rights reserved.
          </p>
          <p>
            crafted with <FontAwesomeIcon icon={faHeart}/>️ for <a href="https://sesf.ch" title={'SeSF official website'}>SESF</a>
          </p>
        </div>
      </div>
    </div>
  </div>
)




export default Footer