import React, { Component } from "react"
import "./Navigation.scss"
import Logo from "../images/SVG/Logo.svg"

class Navigation extends Component {
  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount() {
    this.resizeNavbar()
  }

  resizeNavbar() {
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        document.querySelector(".nav-container").style.height = "60px";
        document.querySelector(".logo").style.height = "40px";
      } else {
        document.querySelector(".nav-container").style.height = "90px";
        document.querySelector(".logo").style.height = "70px";
      }
    }
  }

  render() {
    return (
      <nav className="main-nav">
        <div className="nav-container">
          <div className="logo-wrapper">
            <img src={Logo} alt="" className="logo" />
          </div>
          <div className={'mobile-nav-button'}>
            <button aria-label={'mobile navigation button'}>
              <div className={'bar'}></div>
              <div className={'bar'}></div>
              <div className={'bar'}></div>
            </button>
          </div>
          <ul className="main-menu">
            <li className="menu-item">
              <a href="https://sesf.ch">SESF Official site</a>
            </li>
            <li className="menu-item">
              <a href="#contact-us">Contact Us</a>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}

export default Navigation
