import React, { Component } from "react"
import "./Contact.scss"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false, isSubmited: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => this.setState({isSubmited: true}))
      .catch(error => alert(error))
  }
  render() {
    return (
      <div className="contact-block">
        <div id="contact-us" />
        <div className="contact-block-inner">
          {this.state.isSubmited ? (
            <div className="thankyou">
              <span className="thankyou-text">Thank You !</span>
              <span className={'thankyou-note'}>We'll get back to you soon.</span>
            </div>
          ) : (
            <form
              name="feedback-roadmap"
              method="POST"
              data-netlify="true"
              onSubmit={this.handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contact" />
              <p role="heading" aria-level="3" className="h3-like">
                Send us a message
              </p>
              <p>
                Use this form only to submit your comment and ideas on the SESF
                website. Other topic will not be proceed.
              </p>
              <div className="form-row">
                <div className="form-column">
                  <div className="input-control">
                    <label htmlFor={"name"}>Who are you ?</label>
                    <span>Name, nickname, how should we call you.</span>
                    <input
                      type={"text"}
                      name={"name"}
                      onChange={this.handleChange}
                      id={"name"}
                      required={true}
                    />
                  </div>
                  <div className="input-control">
                    <label htmlFor={'topic'}>What is your idea, request ?</label>
                    <span>This is used to sort messages.</span>
                    <input type={'text'} name={'topic'} id={'topic'} onChange={this.handleChange} required={true}/>
                  </div>
                  <div className="input-control">
                    <label htmlFor={'contact-email'}>Your email adress</label>
                    <span>To recontact you if we need more infos</span>
                    <input type={'email'} name={'contact-email'} onChange={this.handleChange} id={'contact-email'} required={true} />
                  </div>
                </div>
                <div className="form-column">
                  <div className="input-control">
                    <label htmlFor={'message'}>Your message :</label>
                    <span>
                    Give us more context or infos about your idea, request.
                  </span>
                    <textarea
                      className="textarea"
                      name={'message'}
                      onChange={this.handleChange}
                      id={'message'}
                      required={true}
                    />
                  </div>
                  <div className="submit-block">
                    <button type="submit" className="btn btn-submit">
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    )
  }
}

export default Contact
